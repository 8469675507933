import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home";
import Virtual from "./components/virtual";
import About from "./components/about";
import Learning from "./components/learning";
import Contact from "./components/contact";
import Audio from "./components/smartclassroom";
import Virtualclassroom from "./components/virtualclassroom";
import Hybrid from "./components/hybridclassroom";
import Conference from "./components/conferenceroom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/navbar";
import Footer from "./components/footer";
import HeroSlider from "./components/heroslider";
import Product from "./components/product";
import OurProducts from "./components/ourproducts";
import ProductList from "./components/productlist";
import product1 from "./image/product_pic/product1.png";
import product2 from "./image/product_pic/Desktop Document Camera.png";
import product3 from "./image/product_pic/Digital Podium 1.png";
import product4 from "./image/product_pic/Digital Podium.png";
import product5 from "./image/product_pic/IFP7550-5_F01_pc.jpg";
import product6 from "./image/product_pic/IFP7550-5_LF01_pc.jpg";
import product7 from "./image/product_pic/Lumens Camera.png";
import product8 from "./image/product_pic/MEDIA PROCESSOR.png";
import product9 from "./image/product_pic/Viewsonic Ifp1.jpg";
import product10 from "./image/product_pic/Viewsonic Ifp2.jpg";
import ProductDetails from "./components/productdetails";
import PCCChecklistForm from "./components/FormLayout";

const products = [
  {
    id: 1,
    title: "Product 1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi itaque velit eaque autem, dolor aperiam quos dolore assumenda accusantium optioLorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa optio suscipit quos ex architecto minima laudantium omnis harum. Velit minus quis sunt aut officia temporibus rem fuga, odio explicabo. Velit distinctio expedita illum nihil sint magnam deleniti molestiae, similique reiciendis explicabo. Molestias, temporibus natus dolor repellat aspernatur rem quae, magnam eos deleniti maiores aut ea commodi! Maxime sint porro molestiae labore quasi fugit fugiat nihil dolorum quis cum praesentium vel numquam eaque unde aspernatur, deleniti voluptatibus quam consequatur sed voluptatem.",
    image: product1,
    images: [product1],

  },
  {
    id: 2,
    title: "Product 2",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi itaque velit eaque autem, dolor aperiam quos dolore assumenda accusantium optio! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa optio suscipit quos ex architecto minima laudantium omnis harum. Velit minus quis sunt aut officia temporibus rem fuga, odio explicabo. Velit distinctio expedita illum nihil sint magnam deleniti molestiae, similique reiciendis explicabo. Molestias, temporibus natus dolor repellat aspernatur rem quae, magnam eos deleniti maiores aut ea commodi! Maxime sint porro molestiae labore quasi fugit fugiat nihil dolorum quis cum praesentium vel numquam eaque unde aspernatur, deleniti voluptatibus quam consequatur sed voluptatem.",
    image: product2,
    images:[product2]
  },
  {
    id: 3,
    title: "Product 3",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi itaque velit eaque autem, dolor aperiam quos dolore assumenda accusantium optio!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa optio suscipit quos ex architecto minima laudantium omnis harum. Velit minus quis sunt aut officia temporibus rem fuga, odio explicabo. Velit distinctio expedita illum nihil sint magnam deleniti molestiae, similique reiciendis explicabo. Molestias, temporibus natus dolor repellat aspernatur rem quae, magnam eos deleniti maiores aut ea commodi! Maxime sint porro molestiae labore quasi fugit fugiat nihil dolorum quis cum praesentium vel numquam eaque unde aspernatur, deleniti voluptatibus quam consequatur sed voluptatem.",
    image: product3,
    images:[product3,product4]
  },
  //   {
  //     id: 4,
  //     title: 'Product 4',
  //     description: 'Description for Product 4',
  //     image: product4
  //   },
  {
    id: 5,
    title: "Product 5",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi itaque velit eaque autem, dolor aperiam quos dolore assumenda accusantium optio! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa optio suscipit quos ex architecto minima laudantium omnis harum. Velit minus quis sunt aut officia temporibus rem fuga, odio explicabo. Velit distinctio expedita illum nihil sint magnam deleniti molestiae, similique reiciendis explicabo. Molestias, temporibus natus dolor repellat aspernatur rem quae, magnam eos deleniti maiores aut ea commodi! Maxime sint porro molestiae labore quasi fugit fugiat nihil dolorum quis cum praesentium vel numquam eaque unde aspernatur, deleniti voluptatibus quam consequatur sed voluptatem.",
    image: product5,
    images: [product5,product6]

  },
  //   {
  //     id: 6,
  //     title: 'Product 6',
  //     description: 'Description for Product 6',
  //     image: product6
  //   },
  {
    id: 7,
    title: "Product 7",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi itaque velit eaque autem, dolor aperiam quos dolore assumenda accusantium optio!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa optio suscipit quos ex architecto minima laudantium omnis harum. Velit minus quis sunt aut officia temporibus rem fuga, odio explicabo. Velit distinctio expedita illum nihil sint magnam deleniti molestiae, similique reiciendis explicabo. Molestias, temporibus natus dolor repellat aspernatur rem quae, magnam eos deleniti maiores aut ea commodi! Maxime sint porro molestiae labore quasi fugit fugiat nihil dolorum quis cum praesentium vel numquam eaque unde aspernatur, deleniti voluptatibus quam consequatur sed voluptatem.",
    image: product7,
  },
  {
    id: 8,
    title: "Product 8",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi itaque velit eaque autem, dolor aperiam quos dolore assumenda accusantium optio!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa optio suscipit quos ex architecto minima laudantium omnis harum. Velit minus quis sunt aut officia temporibus rem fuga, odio explicabo. Velit distinctio expedita illum nihil sint magnam deleniti molestiae, similique reiciendis explicabo. Molestias, temporibus natus dolor repellat aspernatur rem quae, magnam eos deleniti maiores aut ea commodi! Maxime sint porro molestiae labore quasi fugit fugiat nihil dolorum quis cum praesentium vel numquam eaque unde aspernatur, deleniti voluptatibus quam consequatur sed voluptatem.",
    image: product8,
  },
  {
    id: 9,
    title: "Product 9",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi itaque velit eaque autem, dolor aperiam quos dolore assumenda accusantium optio! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa optio suscipit quos ex architecto minima laudantium omnis harum. Velit minus quis sunt aut officia temporibus rem fuga, odio explicabo. Velit distinctio expedita illum nihil sint magnam deleniti molestiae, similique reiciendis explicabo. Molestias, temporibus natus dolor repellat aspernatur rem quae, magnam eos deleniti maiores aut ea commodi! Maxime sint porro molestiae labore quasi fugit fugiat nihil dolorum quis cum praesentium vel numquam eaque unde aspernatur, deleniti voluptatibus quam consequatur sed voluptatem.",
    image: product9,
    images: [product9,product10]

  },
  //   {
  //     id: 10,
  //     title: 'Product 10',
  //     description: 'Description for Product 10',
  //     image: product10
  //   },
];
function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/virtual" element={<Virtual />} />
        <Route path="/about" element={<About />} />
        <Route path="/learning" element={<Learning />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="" element={<Home />} />
        <Route path="/smartclassroom" element={<Audio />} />
        <Route path="/virtualclassroom" element={<Virtualclassroom />} />
        <Route path="/conferenceroom" element={<Conference />} />
        <Route path="/hybridclassroom" element={<Hybrid />} />
        <Route path="/product" element={<Product />} />
        <Route path="/formlayout" element={<PCCChecklistForm />} />

        <Route path="/products" element={<ProductList products={products} />} />
        <Route
          path="/product/:productId"
          element={<ProductDetails products={products} />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

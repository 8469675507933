import React, { useState, useEffect } from "react";
import "../css/home.css";
import "../js/home";
import video from "../image/videoConference.jpg";
import classroom from "../image/smartClassroom.jpg";
import learning from "../image/ELearning.jpg";
import achievement from "../image/achievement.jpg";
import vr from "../image/VR.jpg";
import HeroSlider from "./heroslider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faChalkboardTeacher, faLaptop, faVrCardboard, faTrophy } from '@fortawesome/free-solid-svg-icons';
export default function Home() {
  return (
    <div>
      <header className="headerContent">
        <div>
          <div classNameName="content">
            <div className="container">
              <div className="row">
                <div className="col-md-12-xs-4 headercontent">
                  
                  <h3>
                    Welcome to
                    <span className="animate-character">TOSHANSH GLOBAL INFO SOLUTIONS</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <p>
          Toshansh Global is pioneering the digital transformation of education and training in India. Our innovative IT solutions empower learners and educators alike, providing a seamless and effective learning experience. Discover how we're revolutionizing the way knowledge is shared and acquired across the nation.

          </p>
        </div>
      </header>
      <HeroSlider />
      <div className="container-fluid">
        <h3 className="serviceBox reveal fade-top">
          Our <span>Services</span>
        </h3>
        <p className="servicePara reveal fade-top">
          Toshansh Technologies India Pvt. Ltd. is a leader in Smart Classrooms,
          Learning Management Systems, and E-Content Development. Their
          innovative solutions revolutionize online learning, offering
          interactive classrooms, efficient course management, and engaging
          multimedia content.
        </p>
        <div className="row mb-5" style={{ justifyContent: "center" }}>
          <div
            className="col-12 col-sm-6 col-md-3  reveal fade-left"
            style={{ width: "36%" }}
          >
            <div className="card shadow">
              <img style={{ height: "326px" }} src={video} alt="My Image" />
              <div className="card-body">
                <h3 className="text-center">Video Conferencing</h3>
                <button className="cardbtn">Learn More</button>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-6 col-md-3  reveal fade-right"
            style={{ width: "36%" }}
          >
            <div className="card shadow">
              <img style={{ height: "326px" }} src={classroom} alt="My Image" />
              <div className="card-body">
                <h3 className="text-center">Smart Classrooms</h3>
                <button className="cardbtn">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mb-5" style={{ justifyContent: "center" }}>
          <div
            className="col-12 col-sm-6 col-md-3  reveal fade-left"
            style={{ width: "36%" }}
          >
            <div className="card shadow">
              <img style={{ height: "326px" }} src={learning} alt="My Image" />
              <div className="card-body">
                <h3 className="text-center">LMS/ELearning</h3>
                <button className="cardbtn">Learn More</button>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-6 col-md-3  reveal fade-right"
            style={{ width: "36%" }}
          >
            <div className="card shadow">
              <img style={{ height: "326px" }} src={vr} alt="My Image" />
              <div className="card-body">
                <h3 className="text-center">VR/AR/XR</h3>
                <button className="cardbtn">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="bg-light py-3 py-md-5" style={{ position: "relative" }}>
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h3
                className="serviceBox reveal fade-top"
                style={{ marginBottom: "0px" }}
              >
                Our <span>Success</span>
              </h3>
              <h2
                class="mb-4 display-5 text-center"
                style={{ fontSize: "30px" }}
              >
                We are proud of our achievements.
              </h2>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row gy-4 gy-lg-0 align-items-lg-center">
            <div class="col-12 col-lg-6">
              <img
                style={{ width: "100%" }}
                src={achievement}
                alt="My Image"
                className="reveal fade-left"
              />
            </div>
            <div class="col-12 col-lg-6 reveal fade-right">
              <div class="row justify-content-xl-end">
                <div class="col-12 col-xl-11">
                  <div class="row gy-4 gy-sm-0 overflow-hidden">
                    <div class="col-12 col-sm-6">
                      <div class="card border-0 border-bottom border-primary shadow-sm mb-4 successBox1">
                        <div class="card-body text-center p-4 p-xxl-5">
                          <h3 class="display-2 fw-bold mb-2">15</h3>
                          <p class="fs-5 mb-0 ">Years of Experience</p>
                        </div>
                      </div>
                      <div class="card border-0 border-bottom border-primary shadow-sm successBox3 ">
                        <div class="card-body text-center p-4 p-xxl-5">
                          <h3 class="display-2 fw-bold mb-2">10k</h3>
                          <p class="fs-5 mb-0 ">Business Partners</p>
                        </div>
                      </div>
                      <div class="card border-0 border-bottom border-primary shadow-sm mt-4 successBox2 ">
                        <div class="card-body text-center p-4 p-xxl-5">
                          <h3 class="display-2 fw-bold mb-2">25M</h3>
                          <p class="fs-5 mb-0 ">Products Installed</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6">
                      <div class="card border-0 border-bottom border-primary shadow-sm mt-lg-6 mt-xxl-8 mb-4 successBox5">
                        <div class="card-body text-center p-4 p-xxl-5">
                          <h3 class="display-2 fw-bold mb-2">22</h3>
                          <p class="fs-5 mb-0 ">Countries World Wide</p>
                        </div>
                      </div>
                      <div class="card border-0 border-bottom border-primary shadow-sm successBox4">
                        <div class="card-body text-center p-4 p-xxl-5">
                          <h3 class="display-2 fw-bold mb-2">5</h3>
                          <p class="fs-5 mb-0 ">Industry Awards</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}







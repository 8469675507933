import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Checkbox,
  Button,
  Paper,
  Divider,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const PCCChecklistForm = () => {
  const [formData, setFormData] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checklistItems = [
    { id: 1, label: "Test conducted on received material & reports" },
    { id: 2, label: "Checklist for excavation" },
    { id: 3, label: "Grade of concrete" },
    { id: 4, label: "Dimensions of excavated area as per approved drawings" },
    { id: 5, label: "Side supports (ensuring the rigidity) fixed" },
    { id: 6, label: "Gradient towards sump pit provided" },
    { id: 7, label: "Compaction test done", tolerance: "Min. 95% Compaction" },
    {
      id: 8,
      label: "Level guide checked by surveyor before starting concreting",
    },
    { id: 9, label: "Extra excavated depth filled with PCC or Plum concrete" },
    {
      id: 10,
      label: "RMC received at site complies with tender specifications & ITP",
    },
    { id: 11, label: "Post PCC final levels checked by surveyor" },
    { id: 12, label: "Adequate curing done" },
  ];

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0 auto" }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h4" align="center" gutterBottom>
          PLAIN CEMENT CONCRETE (PCC) CHECKLIST
        </Typography>
        <Divider sx={{ marginBottom: 3 }} />

        <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Project Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name of Project"
                name="projectName"
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Drawing No."
                name="drawingNo"
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name of Work"
                name="nameOfWork"
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date"
                name="date"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Structural Member"
                name="structuralMember"
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" gutterBottom>
            Checklist
          </Typography>
          <Grid container spacing={2}>
            {checklistItems.map((item) => (
              <Grid item xs={12} key={item.id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "flex-start",
                    mb: 1,
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label={item.label}
                    sx={{ marginRight: 2, flexGrow: 1 }}
                  />
                  <TextField
                    label="Remark"
                    variant="outlined"
                    size="small"
                    fullWidth={isMobile}
                    sx={{ flexGrow: 1, mt: isMobile ? 1 : 0 }}
                  />
                </Box>
                {item.tolerance && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ ml: 3 }}
                  >
                    Tolerance: {item.tolerance}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" gutterBottom>
            Representative Signatures
          </Typography>
          <Divider sx={{ my: 3 }} />

          {[1, 2, 3].map((rep) => (
            <Grid container key={rep} spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label={`Representative ${rep} Name`}
                  name={`rep${rep}Name`}
                  variant="outlined"
                  size="small"
                  onChange={handleInputChange}
                />
              </Grid>
              <Divider sx={{ my: 3 }} />

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label="Designation"
                  name={`rep${rep}Designation`}
                  variant="outlined"
                  size="small"
                  onChange={handleInputChange}
                />
              </Grid>
              <Divider sx={{ my: 3 }} />

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label="Signature"
                  name={`rep${rep}Signature`}
                  variant="outlined"
                  size="small"
                  onChange={handleInputChange}
                />
              </Grid>
              <Divider sx={{ my: 3 }} />

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label="Date"
                  name={`rep${rep}Date`}
                  type="date"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          ))}

          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button variant="contained" color="primary" size="large">
              Submit Checklist
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PCCChecklistForm;

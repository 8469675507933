// import React from 'react';
// import { useParams } from 'react-router-dom';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';

// const ProductDetails = ({ products }) => {
//   const { productId } = useParams(); // Get the productId from the route params

//   const product = products.find((p) => p.id === parseInt(productId, 10));

//   if (!product) {
//     return <div>Product not found</div>;
//   }

//   const { title, description, image } = product;

//   return (
//     <Grid container spacing={3}>
//       <Grid item xs={12} md={6}>
       
//         <img src={image} alt={title} style={{ width: '100%' }} />
//       </Grid>
//       <Grid item xs={12} md={6}>
     
//         <Typography variant="h4" gutterBottom>
//           {title}
//         </Typography>
//         <Typography variant="body1">{description}</Typography>
//       </Grid>
//     </Grid>
//   );
// };

// export default ProductDetails;
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';

const ProductDetails = ({ products }) => {
  const { productId } = useParams();
  const product = products.find((p) => p.id === parseInt(productId, 10));

  const [selectedImage, setSelectedImage] = useState(product.images[0]); // Initial selected image

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  const { title, description, images } = product;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} p={6} mt={10}>
        <div style={{ maxWidth: 400, margin: 'auto' }}>
          <img
            src={selectedImage}
            alt={title}
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
          <Grid container spacing={1} justifyContent="center">
            {images.map((imageUrl, index) => (
              <Grid item key={index}>
                <Paper
                  elevation={selectedImage === imageUrl ? 3 : 0} 
                  style={{
                    width: 60,
                    height: 60,
                    cursor: 'pointer',
                    overflow: 'hidden',
                    marginTop:'5px',
                    borderRadius: 1
                  }}
                  onClick={() => handleImageClick(imageUrl)} // Update selected image on click
                >
                  <img
                    src={imageUrl}
                    alt={title}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={6} p={6} mt={10}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;

import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const OurProducts = ({ title, description, image }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderDescription = () => {
    const maxLength = 100; 

    if (description.length <= maxLength || showFullDescription) {
      return description;
    }

    return (
      <>
        {description.slice(0, maxLength)}...
        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={toggleDescription}>
          Read More
        </span>
      </>
    );
  };

  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardMedia sx={{ height: 250 }} image={image} title={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <Typography variant="body2">{renderDescription()}</Typography>
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
};

export default OurProducts;

import React from "react";
import "../css/product.css";
import "../js/product.js";
import DP1 from "../image/Digital Podium 1.png";
import DP from "../image/Digital Podium.png";

const ProductCard = () => {
  const changeImage = (image) => {
    document.getElementById("main-image").src = image.src;
  };

  return (
    <div className="container mb-5">
      <div
        className="row d-flex justify-content-center"
        style={{ marginTop: "128px" }}
      >
        <div className="col-md-10">
          <div className="card">
            <div className="row">
              <div className="col-md-6">
                <div className="images p-3">
                  <div className="text-center p-4">
                    <img
                      id="main-image"
                      onClick={(e) => changeImage(e.target)}
                      src={DP1}
                      style={{ height: "240px", width: "300px" }}
                      alt="Main Image"
                    />
                  </div>
                  <div className="thumbnail text-center">
                    <img
                      onClick={(e) => changeImage(e.target)}
                      src={DP1}
                      width="70"
                      alt="Thumbnail 1"
                      style={{ height: "65px", marginRight: "10px" }}
                    />
                    <img
                      onClick={(e) => changeImage(e.target)}
                      src={DP}
                      width="70"
                      alt="Thumbnail 2"
                      style={{ height: "65px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="product p-4">
                  <div className="mt-4 mb-3">
                    <h5 className="text-uppercase">Lorem ipsum</h5>
                    <h5 className="text-uppercase">Lorem ipsum dolor sit.</h5>
                  </div>
                  <span class=" text-muted brand">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Eligendi itaque velit eaque autem, dolor aperiam quos dolore
                    assumenda accusantium optio!
                  </span>
                  <p className="about">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Culpa optio suscipit quos ex architecto minima laudantium
                    omnis harum. Velit minus quis sunt aut officia temporibus
                    rem fuga, odio explicabo. Velit distinctio expedita illum
                    nihil sint magnam deleniti molestiae, similique reiciendis
                    explicabo. Molestias, temporibus natus dolor repellat
                    aspernatur rem quae, magnam eos deleniti maiores aut ea
                    commodi! Maxime sint porro molestiae labore quasi fugit
                    fugiat nihil dolorum quis cum praesentium vel numquam eaque
                    unde aspernatur, deleniti voluptatibus quam consequatur sed
                    voluptatem.
                  </p>
                  <div className="cart mt-4 align-items-center">
                    <button className="btn btn-danger text-uppercase mr-2 px-4">
                      Download Brochure
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

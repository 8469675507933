import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#2C3E50', color: '#ECF0F1', padding: '20px 0' }}>
      <div className="container text-center text-md-start">
        <div className="row">

          {/* About Section */}
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 className="text-uppercase font-weight-bold mb-4">Toshansh Technologies</h6>
            <p>
              Enhance your experiences with our cutting-edge solutions in audio-video, LMS, E-content, AR/VR/MR, and more.
            </p>
          </div>

          {/* Links Section */}
          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
            <h6 className="text-uppercase font-weight-bold mb-4">Useful Links</h6>
            <ul className="list-unstyled">
              <li><a href="#" className="text-white" style={{textDecoration:'none'}}>Audio Video Solutions</a></li>
              <li><a href="#" className="text-white "style={{textDecoration:'none'}}>LMS</a></li>
              <li><a href="#" className="text-white" style={{textDecoration:'none'}}>E-Content Development</a></li>
              <li><a href="#" className="text-white" style={{textDecoration:'none'}}>AR/VR/MR</a></li>
            </ul>
          </div>

          {/* About Us and Clients Section */}
          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
            <h6 className="text-uppercase font-weight-bold mb-4">About</h6>
            <ul className="list-unstyled">
              <li><a href="#" className="text-white" style={{textDecoration:'none'}}>Our Clients</a></li>
              <li><a href="#" className="text-white" style={{textDecoration:'none'}}>About Us</a></li>
              <li><a href="#" className="text-white" style={{textDecoration:'none'}}>Contact Us</a></li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 className="text-uppercase font-weight-bold mb-4">Contact</h6>
            <p><i className="fas fa-home mr-3"></i> S/Floor, House No. 93AB, 94AB, West Vikas Vihar, Delhi, 110059</p>
            <p><i className="fas fa-envelope mr-3"></i> info@toshansh.com</p>
            <p><i className="fas fa-phone mr-3"></i> +91-7992350300</p>
          </div>
        </div>
        
        {/* Social Icons and Copyright */}
        <div className="row align-items-center mt-4">
          <div className="col-md-8 col-lg-8 text-center text-md-start">
            <p>© 2020 Toshansh Technologies</p>
          </div>
          <div className="col-md-4 col-lg-4 text-center text-md-end">
            <a href="#" className="text-white mx-2"><i className="fab fa-facebook-f"></i></a>
            <a href="#" className="text-white mx-2"><i className="fab fa-twitter"></i></a>
            <a href="#" className="text-white mx-2"><i className="fab fa-google"></i></a>
            <a href="#" className="text-white mx-2"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

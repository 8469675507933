


import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import OurProducts from './ourproducts';

const ProductList = ({ products }) => {
  
  return (
    <Grid container spacing={3} p={3} mt={10}>
      {products.map((product) => (
        <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
          <Link to={`/product/${product.id}`} style={{ textDecoration: 'none' }}>
            <OurProducts
              title={product.title}
              description={product.description}
              image={product.image}
            />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductList;
